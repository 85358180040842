import { endOfDay, format } from 'date-fns';
import pptxgen from 'pptxgenjs';

import { getStaticAssetPath } from '@lp-lib/email-templates/src/utils';

import { useLiveAsyncCall } from '../../../hooks/useAsyncCall';
import { getQueryParam } from '../../../hooks/useQueryParam';
import { apiService } from '../../../services/api-service';
import { type Organization } from '../../../types';
import { Loading } from '../../Loading';
import { type AnalyticsFilter } from '../../Organization/Details';
import { addSlideEngagement } from './AnalyticsSlideEngagement';
import { addSlideLPUpdate } from './AnalyticsSlideLPUpdate';
import { addSlideNewGames } from './AnalyticsSlideNewGames';
import { pxToInch } from './AnalyticsSlideShared';

function parseDate(dateString: string | null) {
  if (!dateString) return null;
  const [year, month, day] = dateString.split('-').map(Number);
  return new Date(Date.UTC(year, month - 1, day));
}

export function AnalyticsDownloadSlidesBanner(props: {
  organization: Organization;
  filter: AnalyticsFilter;
}): JSX.Element {
  const { organization, filter } = props;

  const {
    call: handleDownload,
    state: {
      state: { isRunning },
    },
  } = useLiveAsyncCall(async () => {
    const startAt = parseDate(getQueryParam('slides-start-at'));
    let endAt = parseDate(getQueryParam('slides-end-at'));
    if (endAt) {
      endAt = endOfDay(endAt);
    }

    const { data: summary } =
      await apiService.organization.getPresentationSummary(organization.id, {
        timeRange: filter.timeRange,
        startAt: startAt ? startAt.toISOString() : null,
        endAt: endAt ? endAt.toISOString() : null,
      });

    const pres = new pptxgen();

    pres.defineLayout({
      name: '1920_1080',
      width: pxToInch(1920),
      height: pxToInch(1080),
    });
    pres.layout = '1920_1080';

    addSlideLPUpdate({ pres, organization, summary });
    addSlideNewGames({ pres, organization, summary });
    await addSlideEngagement({ pres, organization, summary });

    const getFormattedDateRange = (
      startAt: string | null | undefined,
      endAt: string
    ) => {
      const endDate = format(new Date(endAt), 'yyyy.MM.dd');
      if (startAt) {
        const startDate = format(new Date(startAt), 'yyyy.MM.dd');
        return `${startDate} - ${endDate}`;
      }
      return `As of ${endDate}`;
    };

    const fileName = `Luna Park Update - ${organization.name.substring(
      0,
      30
    )} - ${getFormattedDateRange(summary.startAt, summary.endAt)}.pptx`;

    await pres.writeFile({ fileName });
  });

  return (
    <>
      {isRunning && (
        <div className='fixed inset-0 z-10 bg-lp-black-004'>
          <div className='w-full h-full flex flex-col items-center justify-center text-center text-icon-gray'>
            <Loading text='' />
            <div className='mt-2'>
              <p className='text-white text-lg font-bold'>Generating Slides</p>
              <p className='mt-1'>This may take a while. Please be patient.</p>
            </div>
          </div>
        </div>
      )}

      <div
        className='w-full h-25 flex justify-end items-center rounded-2.5xl px-5'
        style={{
          backgroundImage: `url(${getStaticAssetPath(
            'images/analytics/slides-banner.png'
          )})`,
          backgroundSize: 'cover',
        }}
      >
        <div className='flex justify-center items-center gap-7.5'>
          <div className='flex items-center gap-2.5'>
            {organization.logo?.url && (
              <img
                src={organization.logo?.url}
                alt=''
                className='w-12.5 h-12.5 object-contain'
              />
            )}
            <div className='text-white text-xl font-bold italic'>
              {organization.name}
            </div>
          </div>
          <button
            type='button'
            className='btn-secondary w-80 h-10'
            onClick={handleDownload}
            disabled={isRunning}
          >
            {isRunning ? 'Downloading...' : 'Download PPT Slide Summary'}
          </button>
        </div>
      </div>
    </>
  );
}
